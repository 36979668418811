/* global require */

require('core-js/fn/array/includes');
require('core-js/es6/symbol');

window.$ = window.jQuery = require('jquery');
window.Turbolinks = require('turbolinks');

require('jquery-ujs');
require('jquery-ui/ui/core');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/sortable');
require('owl.carousel');

window.console.log('Vendor v1.9.7');
